import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shortid from 'shortid';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import ErrorFocus from './ErrorFocus';
import DestinationField from './Fields/DestinationField';
import MultiCityField from './Fields/MultiCityField';
import DateField from './Fields/DateField';
import SelectFlightField from './Fields/SelectFlightField';
import PassengerField from './Fields/PassengerField';
import TextField from './Fields/TextField';

const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
const requiredMessage = 'This field is required.';

const quoteSchema = Yup.object().shape({
  from: Yup.string().required(requiredMessage),
  to: Yup.string().required(requiredMessage),
  depart: Yup.date()
    .min(yesterday)
    .required(requiredMessage),
  return: Yup.date()
    .nullable()
    .when('flight', {
      is: 'roundTrip',
      then: Yup.date()
        .min(yesterday)
        .required(requiredMessage)
    }),
  destinations: Yup.array().when('flight', {
    is: 'multiCity',
    then: Yup.array().of(
      Yup.object().shape({
        from: Yup.string().required(requiredMessage),
        to: Yup.string().required(requiredMessage),
        depart: Yup.date()
          .min(yesterday)
          .required(requiredMessage)
      })
    )
  }),
  fullName: Yup.string().required(requiredMessage),
  email: Yup.string()
    .email('Please enter a valid email')
    .required(requiredMessage),
  phone: Yup.string().required(requiredMessage)
});

const initialValues = {
  from: '',
  to: '',
  depart: null,
  return: null,
  passengers: {
    adult: 1,
    child: 0,
    infant: 0
  },
  destinations: [{ id: shortid.generate(), from: '', to: '', depart: null }],
  fullName: '',
  email: '',
  phone: '',
  flight: 'roundTrip'
};

const isBusinessClassRoute = () => {
  const location = useLocation();
  return location.pathname.toLowerCase().includes('business');
};

export default function QuoteForm(props) {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [dialCode, setDialCode] = useState('+1');
  const isBusinessClass = isBusinessClassRoute();

  const history = useHistory();
  if (submitSuccess) {
    history.push('/thank-you.html');
  }

  return (
    <Formik
      initialValues={{
        ...initialValues,
        class: isBusinessClass ? 'business' : 'first'
      }}
      validationSchema={quoteSchema}
      onSubmit={async (values, actions) => {
        const ipPromise = fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(json => json.ip)
          .catch(() => 'none');

        const ip = await ipPromise;

        const formatValues = {
          from: values.from,
          to: values.to,
          depart: format(values.depart._d, 'yyyy-MM-dd'),
          return: values.return ? format(values.return._d, 'yyyy-MM-dd') : '',
          passengers: `${values.passengers.adult} Adult(s), ${values.passengers.child} Child(ren), ${values.passengers.infant} Infant(s)`,
          class: values.class,
          destinations:
            values.flight === 'multiCity'
              ? `${values.destinations.reduce((list, destination) => {
                  return `${list} ${destination.from}, ${
                    destination.to
                  } - ${format(destination.depart._d, 'yyyy-MM-dd')}\n`;
                }, `${values.from}, ${values.to} - ${format(values.depart._d, 'yyyy-MM-dd')}\n`)}`
              : '',
          fullName: values.fullName,
          email: values.email,
          phone: `${dialCode}-${values.phone}`,
          flight: values.flight,
          location: props.location.pathname,
          qs: props.location.search,
          ip: ip
          // geoLocation: geoLocation
        };

        fetch(
          `https://lktfa3zbkf.execute-api.us-west-2.amazonaws.com/dev/quote`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formatValues)
          }
        ).then(() => setSubmitSuccess(true));
      }}
    >
      {({
        errors,
        touched,
        props,
        values,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        handleBlur,
        isSubmitting
      }) => (
        <div
          className="p-2 rounded border border-white mx-2"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
          {/*
            <div className="bg-grey-lightest">
              <pre>{JSON.stringify(values, null, 2)}</pre>
              <pre>{JSON.stringify(errors, null, 2)}</pre>
              <pre>{JSON.stringify(touched, null, 2)}</pre>
            </div>
          */}
          <Form>
            <div className="md:flex m-2">
              <SelectFlightField
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="md:flex">
              <div className="md:w-1/5 md:my-2 mt-3 md:ml-2 md:mr-1 mx-2 relative">
                <DestinationField
                  name="from"
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={errors.from && touched.from}
                />
              </div>
              <div className="md:w-1/5 md:my-2 mt-3 md:mx-1 mx-2 relative">
                <DestinationField
                  name="to"
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={errors.to && touched.to}
                />
              </div>
              <div className="flex md:w-2/5 md:my-2 mt-3">
                <div className="md:mx-1 ml-2 mr-1 w-1/2">
                  <DateField
                    setFieldTouched={setFieldTouched}
                    name="depart"
                    value={values.depart}
                    setFieldValue={setFieldValue}
                    error={errors.depart && touched.depart}
                    minDate={new Date()}
                    required={true}
                  />
                </div>
                {values.flight === 'roundTrip' && (
                  <div className="md:mx-1 mr-2 w-1/2">
                    <DateField
                      setFieldTouched={setFieldTouched}
                      name="return"
                      value={values.return}
                      setFieldValue={setFieldValue}
                      error={errors.return && touched.return}
                      disabled={values.flight !== 'roundTrip' && true}
                      minDate={
                        values.depart ? Date.parse(values.depart) : new Date()
                      }
                    />
                  </div>
                )}
              </div>
              <div className="md:mt-0 mt-3 md:mr-3 mr-4 md:w-1/5">
                <PassengerField values={values} setFieldValue={setFieldValue} />
              </div>
            </div>

            {values.flight === 'multiCity' && (
              <FieldArray name="destinations">
                {({ push, remove }) => (
                  <div>
                    {values.destinations.map((d, index) => (
                      <MultiCityField
                        key={d.id}
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        index={index}
                        push={push}
                        remove={remove}
                        errors={errors}
                        touched={touched}
                      />
                    ))}
                  </div>
                )}
              </FieldArray>
            )}

            <div className="md:flex m-2">
              <div className="md:w-1/4 md:mr-1 md:mt-0 mt-3">
                <TextField
                  name="fullName"
                  icon="person"
                  placeholder="Name"
                  error={errors.fullName && touched.fullName}
                  ariaLabel="Full name"
                />
              </div>
              <div className="md:w-1/4 md:mt-0 mt-3 md:mx-1">
                <TextField
                  name="email"
                  icon="email"
                  error={errors.email && touched.email}
                />
              </div>
              <div className="md:w-1/4 md:mt-0 mt-3 md:mx-1">
                <div className="flex">
                  <IntlTelInput
                    containerClassName="intl-tel-input allow-dropdown w-full"
                    inputClassName={`${
                      errors.phone
                        ? 'border-2 border-red-light'
                        : 'border border-blue'
                    } py-3 w-full rounded`}
                    autoComplete="on"
                    value={values.phone}
                    separateDialCode={true}
                    onPhoneNumberChange={(valid, value, country) => {
                      setFieldValue('phone', value);
                      setDialCode(country.dialCode);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="md:w-1/4 md:mt-0 mt-3 md:ml-1">
                <button
                  type="submit"
                  className="p-3 w-full rounded bg-blue text-white hover:bg-white hover:text-blue uppercase"
                  style={{ transition: 'all 125ms ease' }}
                  disabled={isSubmitting}
                >
                  Get Quote
                </button>
              </div>
            </div>
            <ErrorFocus />
          </Form>
        </div>
      )}
    </Formik>
  );
}
